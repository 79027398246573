import LogoImage from '../assets/logo.png';
import {IoReorderThree} from 'react-icons/io5'

function Navbar() {
    return (
        <header className="navbar  flex-md-nowrap p-0 bg-white-navbar">
            <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6 text-black" href="/">                    
                <img 
                    src={LogoImage} 
                    className='img-fluid'
                    width={50}
                    alt='Workhall Community Portal' 
                />
                <strong className='ms-2 fs-4'>work hall</strong>                                            
            </a>            
            <ul className="navbar-nav flex-row d-md-none">
                <li className="nav-item text-nowrap">
                <div className="btn d-lg-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasResponsive" aria-controls="offcanvasResponsive">
                    <IoReorderThree size={50}/>
                </div>
                </li>
            </ul>
        </header>
    );
}

export default Navbar;