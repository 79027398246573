import axios from 'axios';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { BiCheckCircle } from 'react-icons/bi';
import { FaUserAlt } from 'react-icons/fa';
import { FiMoreVertical, FiSearch } from 'react-icons/fi';
import { RxCrossCircled } from 'react-icons/rx';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { useSelector } from 'react-redux';
import Loader from '../common/Loader';
import Pagination from '../Pagination';
import GrantAccess from './GrantAccess';
import { users } from '../../config/api-routes';

const header = ['First Name', 'Last Name', 'Email', 'Phone', 'Type', 'Verified'];

function UserList() {
    const [usersData, setUsersData] = useState([]);
    const [pagination, setPagination] = useState({
        total: 0,
        page: 1,
    });
    const [type, setType] = useState('');
    const [text, setText] = useState('');
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState({
        id: '',
        type: '',
    });
    const { user } = useSelector(state => state.auth);

    const fetchUsers = useCallback(async () => {
        try {
            setLoading(true);
            const { data } = await axios.get(`${users}?page=${pagination.page}&type=${type}&search=${text}`);
            setUsersData(data.users);
            setPagination({
                total: data.total,
                page: data.page,
            });
            setLoading(false);
        } catch (error) {
            swal('Failed', error.response.data.message, 'error');
        }
    }, [pagination.page, text, type]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    return (
        <div>
            <div className='users container m-4 pe-5 mt-4'>
                <div className='col-4'>
                    <h2 className='fw-bold'>Users</h2>
                    <p className='text-secondary'>{pagination.total} users</p>
                </div>
                <div className='row mx-3  pt-4'>
                    <div className='col-8'>
                        <div className=' input-group'>
                            <span className='input-group-text'><FiSearch /></span>
                            <input
                                type='text'
                                className='form-control search'
                                placeholder='Search by name or email'
                                onChange={e => setText(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='row align-items-center'>
                            <div className='col-6 text-end'>Filter By:</div>
                            <div className='col-6'>
                                <select className='form-select' onChange={e => setType(e.target.value)}>
                                    <option value=''>Type</option>
                                    <option value='admin'>Admin</option>
                                    <option value='manager'>Manager</option>
                                    <option value='member'>Member</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                {loading ?
                    <Loader />
                    : (
                        <div className='bg-white rounded '>
                            {usersData.length ? (
                                <div className='m-4 py-4'>
                                    <div className='table-responsive m-2 '>
                                        <table className='table shadow '>
                                            <thead className='table-primary-head'>
                                                <tr>
                                                    <td>#</td>
                                                    {header.map((each, idx) => (
                                                        <td key={idx}>{each}</td>
                                                    ))}
                                                    <td />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {usersData.map((each = {}, idx) => (
                                                    <tr key={idx}>
                                                        <th scope='row'>{`${((pagination.page - 1) * 10) + idx + 1}`}</th>
                                                        <td>{each.firstName}</td>
                                                        <td>{each.lastName}</td>
                                                        <td>{each.email}</td>
                                                        <td>{each.phone}</td>
                                                        <td className='text-capitalize'>
                                                            <span className='pe-2'>
                                                                <FaUserAlt className={classNames(each.type === 'admin' ? 'text-secondary' : each.type === 'manager' ? 'text-success' : 'text-primary')} />
                                                            </span>{each.type}
                                                        </td>
                                                        <td>{each.verified ?
                                                            <div>
                                                                <BiCheckCircle className='text-success' />
                                                            </div>
                                                            : <div>
                                                                <RxCrossCircled className='text-danger' />
                                                            </div>}
                                                        </td>
                                                        <td className='text-end'>
                                                            {user.type === 'admin' ? (
                                                                <div className="dropdown">
                                                                    <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <FiMoreVertical />
                                                                    </span>
                                                                    <ul className="dropdown-menu">
                                                                        <li>
                                                                            <button
                                                                                type="button"
                                                                                className="dropdown-item btn"
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#grantAccess"
                                                                                onClick={() => setOpenModal({ id: each._id, type: each.type })}>
                                                                                Grant Access
                                                                            </button>
                                                                        </li>
                                                                        <li><button className="dropdown-item" type="button">Block Access</button></li>
                                                                        <li><Link to={`/bookings/user/${each._id}`} className="dropdown-item" type="button">Show Bookings</Link></li>
                                                                    </ul>
                                                                </div>
                                                            ) : null}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <GrantAccess id={openModal.id} type={openModal.type} reload={() => fetchUsers()} />
                                    <Pagination
                                        currentPage={pagination.page}
                                        setCurrentPage={(pageRec) => setPagination({ ...pagination, page: pageRec })}
                                        totalRecords={pagination.total}
                                    />
                                </div>
                            ) : <p>No Users found</p>}
                        </div>)}
            </div>
        </div>
    );
}

export default UserList;
