import React from 'react';
import { Page, Document,Text,View,Image,StyleSheet, Line } from '@react-pdf/renderer';
import WorkHallImage from "../../assets/logo.png";
import InvoiceTitle from './title'
import InvoiceNo from './invoiceNo'
import BillTo from './billTo'
import InvoiceItemsTable from './itemsTable'
import InvoiceTableFooter from './tableFooter';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
        position:'relative',
    }, 
    line: {
        position: 'absolute',
        bottom: 60,
        right: 50,
        width: 120,
        height: 1,
        backgroundColor: '#000000',
      },
      text: {
        position: 'absolute',
        width:110,
        bottom: 30,
        right: 55,
        fontSize: 17,
        textAlign: 'center',
        // width:'100%'
      },
    logo: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
  });
  
  const Invoice = ({invoice}) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View>
                <Image style={styles.logo} src={WorkHallImage} />
                <InvoiceTitle title='Invoice'/>
                <InvoiceNo invoice={invoice}/>
                <BillTo invoice={invoice}/>
                <InvoiceItemsTable invoice={invoice} />
                <InvoiceTableFooter invoice={invoice} />
            </View>
            <Line style={styles.line} />
            <Text style={styles.text}>work hall</Text>
        </Page>
    </Document>
);
  
  export default Invoice;