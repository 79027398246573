import { Link, useRouteMatch } from 'react-router-dom';
import { HiLocationMarker, HiOutlineLogout, HiUsers } from 'react-icons/hi';
import { FaAddressBook } from 'react-icons/fa'
import { RiLayoutMasonryFill,RiCommunityFill } from 'react-icons/ri';
import { MdInventory } from 'react-icons/md';
import Navbar from './Nav';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../store/actions/auth'
import avatar from '../assets/placeholder.png';

const appRoutes = [
    { name: 'Dashboard', url: '/dashboard',icon:<RiLayoutMasonryFill/> }, 
    { name: 'Bookings', url: '/bookings' ,icon:<FaAddressBook/> }, 
    { name: 'Locations', url: '/locations' ,icon:<HiLocationMarker/>}, 
    { name: 'Inventory', url: '/inventory' ,icon:<MdInventory/> }, 
    { name: 'Users', url: '/users' ,icon:<HiUsers/> }, 
    { name: 'Community', url: '/community' ,icon:<RiCommunityFill/>}, 
];

function Layout({ children }) {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const routeMatch = useRouteMatch();
    const routes = 
        appRoutes.map((route, idx) => (
            <li key={idx} className='mb-2'>                
                <Link to={route.url} className={'nav-link text-secondary' + (route.url === routeMatch.url ? ' active' : '')}>
                    <span className='me-3'>{route.icon}</span>
                    {route.name}                    
                </Link>
            </li>
        ));

    return (
        <>
            <Navbar/>
            <main >                      
                <div className="offcanvas-md offcanvas-end" tabindex="-1" id="offcanvasResponsive" aria-labelledby="offcanvasResponsiveLabel">
                    <div className='sidebar d-flex flex-column ps-3 pe-3 pb-3 bg-white' >                
                        <Link to={'/users/account/' + auth.user.id} className='d-flex flex-column mb-4 align-items-center text-decoration-none'>                        
                            <img src={auth.user.image ? auth.user.image : avatar} alt='' width='65' height='65' className='rounded-circle me-2' />                        
                            <span className='pt-3 fw-bold fs-5'>{auth.user.name}</span>
                            <span className='text-secondary text-capitalize '>{auth.user.type}</span>
                        </Link>                    
                        <ul className='nav nav-pills flex-column mb-auto'>                        
                            {routes}
                        </ul>                            
                        <a href='#' className='nav nav-pills nav-link text-danger ms-1 ' onClick={() => dispatch(logoutUser())}>
                            <span className='pe-3'><HiOutlineLogout/></span>
                            Log out
                        </a>                        
                    </div>
                </div>                
                <div className='container-fluid m-2 main-content'>
                    {children}
                </div>            
            </main>
        </>
    );
}

export default Layout;