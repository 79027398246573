/* eslint-disable jsx-a11y/anchor-is-valid */
function Pagination({
    currentPage, setCurrentPage, totalRecords,
}) {

    const recordsPerPage = 10;
    const nPages = Math.ceil(totalRecords / recordsPerPage);
    const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

    return (
        <nav aria-label='Table Pagination'>
            <ul className='pagination justify-content-end'>
                <li className={`page-item ${currentPage === 1 ? 'disabled' : null}`}>
                    <a className="page-link"
                        onClick={() => setCurrentPage(currentPage - 1)}>
                        Previous
                    </a>
                </li>
                {pageNumbers.map(pgNumber => (
                    <li key={pgNumber}
                        className={`page-item ${currentPage === pgNumber ? 'active' : null}`}>
                        <a onClick={() => setCurrentPage(pgNumber)}
                            className='page-link'>
                            {pgNumber}
                        </a>
                    </li>
                ))}
                <li className={`page-item ${currentPage === nPages ? 'disabled' : null}`}>
                    <a className="page-link"
                        onClick={() => setCurrentPage(currentPage + 1)}>
                        Next
                    </a>
                </li>
            </ul>
        </nav>
    );
}

export default Pagination;
