import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { bookings, locations } from '../../config/api-routes';
import { diffInDays, diffInHours, diffInMonths } from '../../utils/dates';
import 'react-bootstrap-typeahead/css/Typeahead.css';

const paymentModes = ['Cash', 'Cheque', 'Online'];

function EditBooking() {
    const [criteria, setCriteria] = useState(1);
    const [flag, setFlag] = useState(true);
    const [payload, setPayload] = useState({
        startDate: moment().format('yyyy-MM-dd'),
        endDate: moment().add('M', 1).format('yyyy-MM-dd'),
        hoursFrom: '',
        hoursTo: '',
        isMonthly: true,
        isDaily: false,
        isHourly: false,
        amount: 0,
        isPaid: false,
        paymentMode: 'cash',
        inventory: '',
        location: {},
        createdBy: {},
        notes: '',
    });
    const [locationData, setLocation] = useState({});
    const history = useHistory();
    const params = useParams();

    const fetchLocations = useCallback(async (id) => {
        try {
            const { data } = await axios.get(`${locations}/${id}`);
            setLocation(data);
        } catch (error) {
            swal('Failed', error.response.data.message, 'error');
        }
    }, []);

    const fetchBooking = useCallback(async () => {
        try {
            const { data } = await axios.get(`${bookings}/${params.id}`);
            setPayload({
                ...data,
                hoursFrom: moment(`${moment(data.startDate).format('YYYY-MM-DD')} ${moment(data.hoursFrom).format('HH:mm')}`).format('HH:mm'),
                hoursTo: moment(`${moment(data.endDate).format('YYYY-MM-DD')} ${moment(data.hoursTo).format('HH:mm')}`).format('HH:mm'),
            });
            await fetchLocations(data.location);
            if (data.isMonthly) {
                setCriteria(1);
            } else if (data.hoursFrom && data.hoursTo) {
                setCriteria(3);
            } else {
                setCriteria(2);
            }
        } catch (error) {
            swal('Failed', error.response.data.message, 'error');
        }
    }, [params.id, fetchLocations]);

    useEffect(() => {
        fetchBooking();
    }, [fetchBooking]);

    const handleSubmit = async (e) => {
        const total = handleAmount(e);

        let dataToSubmit = {
            ...payload,
            startDate: moment(payload.startDate).valueOf(),
            endDate: moment(payload.endDate).valueOf(),
            amount: total,
        };
        delete dataToSubmit.inventory;
        delete dataToSubmit.createdBy;
        delete dataToSubmit.location;
        delete dataToSubmit.notes;
        delete dataToSubmit._id;
        delete dataToSubmit.updatedAt;
        delete dataToSubmit.createdAt;

        if (!payload.isMonthly) {
            dataToSubmit = {
                ...dataToSubmit,
                hoursFrom: moment(`${payload.startDate}:${payload.hoursFrom}`).valueOf(),
                hoursTo: moment(`${payload.endDate}:${payload.hoursTo}`).valueOf(),
            };
        } else {
            delete dataToSubmit.hoursFrom;
            delete dataToSubmit.hoursTo;
        }

        try {
            const { data } = await axios.put(`${bookings}/${params.id}`, dataToSubmit);
            if (data) {
                swal('Good Job!', 'Booking updated successfully', 'success').then(() => history.push('/bookings'));
            }
        } catch (error) {
            console.log(error);
            swal('Failed', error.response.data.message, 'error');
        }
    };

    const handleChange = (e) => {
        setFlag(false);
        const val = e.target.value;
        if(val == 1) {
            setPayload({ ...payload, isMonthly: true, isDaily: false, isHourly: false});
        }else if(val == 2) {
            setPayload({ ...payload, isMonthly: false, isDaily: true, isHourly: false});
        }else {
            setPayload({ ...payload, isMonthly: false, isDaily: false, isHourly: true});
        }
    };

    const handleAmount = (e) => {
        e.preventDefault();
        setFlag(true);
        let total = 0;
        const valueOf = {
            startDate: moment(payload.startDate).valueOf(),
            endDate: moment(payload.endDate).valueOf(),
            hoursFrom: moment(moment(payload.startDate).format('YYYY-MM-DD') + ':' + payload.hoursFrom).valueOf(),
            hoursTo: moment(moment(payload.endDate).format('YYYY-MM-DD') + ':' + payload.hoursTo).valueOf(),
        };
        if (payload.isMonthly) {
            if (!diffInMonths(valueOf.startDate, valueOf.endDate)) {
                return swal('Not allowed', 'Start & End Months cannot be same', 'error');
            }
            total = payload.inventory.pricePerMonth *
                diffInMonths(valueOf.startDate, valueOf.endDate);
        }
        if (payload.isDaily) {
            if (!diffInDays(valueOf.startDate, valueOf.endDate)) {
                return swal('Not allowed', 'Start & End Days cannot be same', 'error');
            }
            total = payload.inventory.pricePerDay *
                diffInDays(valueOf.startDate, valueOf.endDate);
        }
        if (payload.isHourly) {
            if (!diffInHours(valueOf.hoursFrom, valueOf.hoursTo)) {
                return swal('Not allowed', 'Hours from and to cannot be same', 'error');
            }
            total = payload.inventory.pricePerHour *
                diffInHours(valueOf.hoursFrom, valueOf.hoursTo);
        }
        setPayload({ ...payload, amount: total });

        return total;
    };

    return (
        <div className='container w-50 bg-white p-3 rounded m-auto mt-5'>
            <h2>Edit Booking for {payload.createdBy.firstName + ' ' + payload.createdBy.lastName}</h2>
            <div className='row mt-5 justify-content-center'>
                <div className='col-12 mb-3'>
                    <span className='pe-3'>Select costing criteria </span>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="subscription1"
                            value="1"
                            checked={payload.isMonthly}
                            onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="subscription1">Per Month</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="subscription2"
                            value="2"
                            checked={payload.isDaily}
                            onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="subscription2">Per Day</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="subscription3"
                            value="3"
                            checked={payload.isHourly}
                            onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="subscription3">Per Hour</label>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='form-floating mb-3'>
                        <input
                            type='text'
                            className='form-control'
                            id='location'
                            value={locationData.title}
                            readOnly
                        />
                        <label htmlFor='locations'>Location</label>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='form-floating mb-3'>
                        <input
                            type='text'
                            className='form-control'
                            id='inventory'
                            value={payload.inventory.title}
                            readOnly
                        />
                        <label htmlFor='inventory'>Inventory</label>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='form-floating mb-3'>
                        <input
                            type='date'
                            className='form-control'
                            id='startDate'
                            value={moment(payload.startDate).format('YYYY-MM-DD')}
                            onChange={e => {
                                setPayload({ ...payload, startDate: e.target.value });
                                setFlag(false);
                            }}
                            min={new Date().toISOString().split('T')[0]}
                            required
                        />
                        <label htmlFor='startDate'>Start Date *</label>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='form-floating mb-3'>
                        <input
                            type='date'
                            className='form-control'
                            id='endDate'
                            value={moment(payload.endDate).format('YYYY-MM-DD')}
                            onChange={e => {
                                setPayload({ ...payload, endDate: e.target.value });
                                setFlag(false);
                            }}
                            min={new Date().toISOString().split('T')[0]}
                            required
                        />
                        <label htmlFor='endDate'>End Date *</label>
                    </div>
                </div>
                {!payload.isMonthly && (
                    <>
                        <div className='col-6'>
                            <div className='form-floating mb-3'>
                                <input
                                    type='time'
                                    className='form-control'
                                    id='hoursFrom'
                                    value={payload.hoursFrom}
                                    min={moment().format('HH:mm')}
                                    onChange={e => {
                                        setPayload({ ...payload, hoursFrom: moment(`${moment(payload.startDate).format('YYYY-MM-DD')} ${e.target.value}`).format('HH:mm') });
                                        setFlag(false);
                                    }}
                                    required
                                />
                                <label htmlFor='hoursFrom'>Hours from</label>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='form-floating mb-3'>
                                <input
                                    type='time'
                                    className='form-control'
                                    id='hoursTo'
                                    value={payload.hoursTo}
                                    onChange={e => {
                                        setPayload({ ...payload, hoursTo: moment(`${moment(payload.endDate).format('YYYY-MM-DD')} ${e.target.value}`).format('HH:mm') });
                                        setFlag(false);
                                    }}
                                    required
                                />
                                <label htmlFor='hoursTo'>Hours to</label>
                            </div>
                        </div>
                    </>
                )}
                <div className='col-12'>
                    <div className='form-floating mb-3'>
                        <select
                            className='form-select'
                            id='paymentMode'
                            required
                            value={payload.paymentMode}
                            onChange={e => setPayload({ ...payload, paymentMode: e.target.value })}>
                            <option></option>
                            {paymentModes
                                .map((each, idx) => <option key={idx} value={each}>{each}</option>
                                )}
                        </select>
                        <label htmlFor='paymentMode'>Payment Mode *</label>
                    </div>
                </div>
                <div className='col-9'>
                    <div className='form-floating mb-3'>
                        <textarea
                            className='form-control'
                            id='notes'
                            value={payload.notes}
                            onChange={e => setPayload({ ...payload, notes: e.target.value })}
                            placeholder='Add details for the payment'
                            readOnly
                        ></textarea>
                        <label htmlFor='notes'>Notes</label>
                    </div>
                </div>
                <div className='col-3'>
                    <div className="form-check pt-3">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={payload.isPaid}
                            id="isPaid"
                            onChange={e => setPayload({ ...payload, isPaid: e.target.checked })}
                        />
                        <label className="form-check-label" htmlFor="isPaid">
                            Mark as paid
                        </label>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='form-floating mb-3'>
                        <input
                            type='number'
                            className='form-control'
                            id='amount'
                            value={payload.amount}
                            readOnly
                        />
                        <label htmlFor='amount'>Amount</label>
                    </div>
                </div>
                <div className='col-3 m-auto'>
                    {!flag && (
                        <button
                            className='btn btn-primary btn-wh text-nowrap'
                            onClick={handleAmount}>Get Amount</button>
                    )}
                </div>
                <div className='col-7' />
                <div className='col-3'>
                    {flag && (
                        <button
                            className='btn btn-primary btn-wh px-5'
                            onClick={handleSubmit}>Submit</button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default EditBooking;