import moment from 'moment';

export const diffInMonths = (dateFrom, dateTo) => {
    const dateFr = new Date(dateFrom);
    const dateTill = new Date(dateTo);
    const startYear = dateFr.getFullYear();
    const startMonth = dateFr.getMonth();
    const endYear = dateTill.getFullYear();
    const endMonth = dateTill.getMonth();
    return (
        (endMonth - startMonth) + 12 * (endYear - startYear) + 
        dateTill.getDate() / new Date(endYear, endMonth + 1, 0).getDate()
    );
};

// Test cases for diffInMonths
// Test case 1:
// - Start Date: January 15, 2023
// - End Date: February 10, 2023
// - Expected Output: 0.5

// Test case 2:
// - Start Date: March 15, 2023
// - End Date: July 10, 2023
// - Expected Output: 3.32 (approximately)

// Test case 3:
// - Start Date: December 31, 2022
// - End Date: January 1, 2023
// - Expected Output: 0.0328 (approximately)

// Test case 4:
// - Start Date: September 1, 2022
// - End Date: April 1, 2024
// - Expected Output: 31.0328 (approximately)

export const diffInDays = (dateFrom, dateTo) =>
    Math.ceil((dateTo - dateFrom) / (1000 * 3600 * 24));

export const diffInHours = (dateFrom, dateTo) => {
    const duration = moment.duration(moment(dateTo).diff(moment(dateFrom)));
    return duration.asHours();
}

// ``` old code ```


// import moment from 'moment';

// export const diffInMonths = (dateFrom, dateTo) => {
//     const dateFr = new Date(dateFrom);
//     const dateTill = new Date(dateTo);
//     return (
//         dateTill.getMonth() -
//         dateFr.getMonth() +
//         12 * (dateTill.getFullYear() - dateFr.getFullYear())
//     );
// };

// export const diffInDays = (dateFrom, dateTo) =>
//     Math.ceil((dateTo - dateFrom) / (1000 * 3600 * 24));

// export const diffInHours = (dateFrom, dateTo) => {
//     const duration = moment.duration(moment(dateTo).diff(moment(dateFrom)));
//     return duration.asHours();
// }
