import { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { FiPlus, FiMoreVertical, FiSearch } from 'react-icons/fi';
import { BsCircleFill } from 'react-icons/bs'
import swal from 'sweetalert';
import { inventory, locations } from '../../config/api-routes';
import Loader from "../common/Loader";
import classNames from 'classnames';
const header = ['Title', 'Category', '$/hr', '$/day', '$/month', 'Location'];
export const categories = ['Open', 'Exclusive', 'Meetings'];

function InventoryList() {
    const [data, setData] = useState([]);
    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('');
    const [location, setLocation] = useState('');
    const [locationsData, setLocations] = useState([]);
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    const fetchInventory = useCallback(async () => {
        try {
            setLoading(true);
            const { data } = await axios.get(`${inventory}?title=${title}&category=${category}&location=${location}&isAdmin=true`);
            setData(data.inventory);
            setLoading(false);
        } catch (error) {
            swal('Failed', error.response.data.message, 'error')
                .then(() => history.push('/inventory/new'));
        }
    }, [title, category, location, history]);

    const fetchLocations = useCallback(async () => {
        try {
            setLoading(true);
            const { data } = await axios.get(`${locations}`);
            setLocations(data);
            setLoading(false);
        } catch (error) {
            swal('Failed', error.response.data.message, 'error')
                .then(() => history.push('/locations/new'));
        }
    }, [history]);

    useEffect(() => {
        fetchInventory();
        fetchLocations();
    }, [fetchInventory, fetchLocations]);

    return (
        <div>
            <div className='users container m-4 pe-5 mt-4'>
                <div className='col-2'>
                    <h2 className='fw-bold'>Inventory</h2>
                </div>
                <div className='row mx-3 pt-4'>
                    <div className='col-6'>
                        <div className='input-group'>
                            <span className='input-group-text'><FiSearch /></span>
                            <input
                                type='text'
                                className='form-control search'
                                placeholder='Search by title'
                                onChange={e => { setTitle(e.target.value); fetchInventory(); }}
                            />
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='row align-items-center'>
                            {/* <div className='col-2 text-end'>Filter By:</div> */}
                            <div className='col-4 offset-1'>
                                <select className='form-select' onChange={e => { setCategory(e.target.value); fetchInventory(); }}>
                                    <option value=''>Category</option>
                                    {categories.map((each, idx) => <option key={idx} value={each}>{each}</option>)}
                                </select>
                            </div>
                            <div className='col-4'>
                                <select className='form-select' onChange={e => { setLocation(e.target.value); fetchInventory(); }}>
                                    <option value=''>Location</option>
                                    {locationsData.map((each, idx) => <option key={idx} value={each._id}>{each.title}</option>)}
                                </select>
                            </div>
                            <div className='col-3 d-flex justify-content-end'>
                                <Link to='inventory/new' className='btn btn-primary btn-wh w-75'><FiPlus size={20} /></Link>
                            </div>
                        </div>
                    </div>

                </div>
                {loading ? <Loader /> : (
                    <div className='bg-white rounded m-4'>
                        {data.length ? (
                            <div className='m-4 py-4'>
                                <div className='table-responsive'>
                                    <table className='table shadow'>
                                        <thead className='table-primary-head'>
                                            <tr>
                                                <td>#</td>
                                                {header.map((each, idx) => (
                                                    <td key={idx}>{each}</td>
                                                ))}
                                                <td></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((each = {}, idx) => (
                                                <tr key={idx}>
                                                    <th scope='row'>{idx + 1}</th>
                                                    <td>{each.title}</td>
                                                    <td><BsCircleFill size={20} className={classNames('pe-2', each.category === 'Meetings' ? 'text-success' : each.category === 'Open' ? 'text-primary' : 'text-info')} />{each.category}</td>
                                                    {/* BsCircleFill */}
                                                    <td>{each.pricePerHour}</td>
                                                    <td>{each.pricePerDay}</td>
                                                    <td>{each.pricePerMonth}</td>
                                                    <td>{each.location.title}</td>
                                                    <td className='text-end'>
                                                        <div className="dropdown">
                                                            <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <FiMoreVertical />
                                                            </span>
                                                            <ul className="dropdown-menu">
                                                                <li><Link to={`/inventory/${each._id}`} className="dropdown-item">Manage</Link></li>
                                                                <li><Link to={`/inventory/images/${each._id}`} className="dropdown-item">Upload Images</Link></li>
                                                                <li><Link to={`/bookings/inventory/${each._id}`} className="dropdown-item" type="button">Show Bookings</Link></li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ) : <p>No Inventory found</p>}
                    </div>)}
            </div>
        </div>
    );
}

export default InventoryList;