import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        textAlign: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    description: {
        width: '35%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
    rate: {
        width: '27%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
    amount: {
        width: '15%',
        paddingRight: 8,
    },
    total:{
        width:'30%'
    }
  });


const InvoiceTableRow = ({invoice}) => {
    const rows =  
        <View style={styles.row}>
            <Text style={styles.description}>{invoice.inventory.title}</Text>
            <Text style={styles.description}>{invoice.inventory.category}</Text>
            <Text style={styles.rate}>{moment(invoice.startDate).format('DD-MMM-YYYY')}</Text>
            <Text style={styles.rate}>{moment(invoice.endDate).format('DD-MMM-YYYY')}</Text>
            <Text style={styles.amount}>{invoice.amount}</Text>
            {/* <Text style={styles.total}>TOTAL: {invoice.amount}</Text> */}
            
        </View>
    
    return (<Fragment>{rows}</Fragment> )
};
  
export default InvoiceTableRow