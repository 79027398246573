import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import moment from 'moment';
import { bookings, inventory, locations, users } from '../../config/api-routes';
import { diffInDays, diffInHours, diffInMonths } from '../../utils/dates';
import WHLogo from '../../assets/logo.png';
import 'react-bootstrap-typeahead/css/Typeahead.css';

const paymentModes = ['Cash', 'Cheque', 'Online'];

function CreateBooking() {
    const [payload, setPayload] = useState({
        startDate: moment(moment().format("YYYY-MM-DD"), 'YYYY-MM-DD').valueOf(),
        endDate: moment(moment().format("YYYY-MM-DD"), 'YYYY-MM-DD').valueOf(),
        hoursFrom: moment(moment().format("YYYY-MM-DD:HH:mm:ss"), 'YYYY-MM-DD:HH:mm:ss').valueOf() - moment(moment().format("YYYY-MM-DD"), 'YYYY-MM-DD').valueOf() - 18000000,
        hoursTo: moment(moment().format("YYYY-MM-DD:HH:mm:ss"), 'YYYY-MM-DD:HH:mm:ss').valueOf() - moment(moment().format("YYYY-MM-DD"), 'YYYY-MM-DD').valueOf() - 18000000,
        isMonthly: true,
        isDaily: false,
        isHourly: false,
        amount: 0,
        isPaid: false,
        paymentMode: 'cash',
        inventory: '',
        location: '',
        createdBy: '',
        notes: '',
    });

    const [locationsData, setLocations] = useState([]);
    const [inventoryData, setInventory] = useState([]);
    const [usersData, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [isAvailable, setIsAvailable] = useState(true);


    const fetchLocations = useCallback(async () => {
        try {
            const { data } = await axios.get(`${locations}`);
            setLocations(data);
        } catch (error) {
            swal('Failed', error.response.data.message, 'error');
        }
    }, []);

    const fetchInventory = useCallback(async (e) => {
        try {
            const { data } = await axios.get(`${inventory}?location=${payload.location}&isAdmin=true`);
            setInventory(data.inventory);
        }
        catch (error) {
            swal('Failed', error.response.data.message, 'error');
        }
    }, []);

    const handleUsers = useCallback(async (query) => {
        setIsLoading(true);
        try {
            const { data } = await axios.get(`${users}?search=${query}&type=member`);
            setUsers(data.users);
        } catch (error) { }
        setIsLoading(false);
    }, []);

    const handleSubmit = async () => {
        try {
            const { data } = await axios.post(bookings, payload);
            if (data) {
                swal('Good Job!', 'Booking created successfully', 'success').then(() => history.push('/bookings'));
            }
        } catch (error) {
            swal('Failed', error.response.data.message, 'error');
        }
    };

    const handleChange = (e) => {
        const val = e.target.value;
        if (val == 1) {
            setPayload({ ...payload, isMonthly: true, isDaily: false, isHourly: false });
        } else if (val == 2) {
            setPayload({ ...payload, isMonthly: false, isDaily: true, isHourly: false });
        } else {
            setPayload({ ...payload, isMonthly: false, isDaily: false, isHourly: true });
        }
    };

    const handleAmount = (e) => {
        e.preventDefault();
        let total = 0;
        const inventory = inventoryData.filter((each) => each._id === payload.inventory)[0];
        if (payload.isMonthly) {
            if (diffInMonths(payload.startDate, payload.endDate) < 0) {
                return swal('Not allowed', 'End Month cannot be before Start Month', 'error');
            }
            total = inventory.pricePerMonth *
                diffInMonths(payload.startDate, payload.endDate);
        }
        if (payload.isDaily) {
            if (diffInDays(payload.startDate, payload.endDate) < 0) {
                return swal('Not allowed', 'End Day cannot be before Start Day', 'error');
            }
            total = inventory.pricePerDay *
                diffInDays(payload.startDate, payload.endDate);
        }
        if (payload.isHourly) {
            if (diffInHours(payload.hoursFrom, payload.hoursTo) < 0) {
                return swal('Not allowed', 'Hours to cannot be before Hours from', 'error');
            }
            total = inventory.pricePerHour *
                diffInHours(payload.hoursFrom, payload.hoursTo);
        }
        total = Math.ceil(total);
        setPayload({ ...payload, amount: total });

        return total;
    };

    const computeDate = value => {
        return moment(moment(value).format('YYYY-MM-DD'), 'YYYY-MM-DD').valueOf();
    }

    const computeTime = value => {
        const [hours, minutes, seconds] = value.split(':').map(Number);
        let computeTimeValue = new Date(payload.startDate);
        computeTimeValue.setHours(hours);
        computeTimeValue.setMinutes(minutes);
        computeTimeValue.setSeconds(seconds);
        computeTimeValue = computeTimeValue - payload.startDate - 18000000;
        return computeTimeValue;
    }

    const checkAvailability = useCallback(async () => {
        if (locationsData.length && inventoryData.length && payload.inventory) {
            const computeInventory = inventoryData.find(each => each._id === payload.inventory).title;
            const payloadToCheck = {
                startDate: payload.startDate,
                endDate: payload.endDate,
                hoursFrom: payload.hoursFrom,
                hoursTo: payload.hoursTo,
                location: payload.location,
                inventory: computeInventory,
                isMonthly: payload.isMonthly,
                isDaily: payload.isDaily,
                isHourly: payload.isHourly,
            };
            try {
                const { data } = await axios.post(`${bookings}/availability`, payloadToCheck);
                if (typeof data === 'boolean') { // true if inventory available, false if inventory unavailable
                    setIsAvailable(data);
                }
            } catch (error) {
                swal('Failed', error.response.data.message, 'error');
            }
        }
    }, [payload.inventory, payload.location, payload.startDate, payload.endDate, payload.hoursFrom, payload.hoursTo]);

    useEffect(() => {
        fetchLocations();
    }, [fetchLocations]);
    useEffect(() => {
        fetchInventory();
    }, [fetchInventory, payload.location]);
    useEffect(() => {
        checkAvailability();
    }, [checkAvailability, payload.inventory, payload.location, payload.startDate, payload.endDate, payload.hoursFrom, payload.hoursTo]);

    return (
        <div className='container w-50 bg-white p-3 rounded m-auto mt-5'>
            <h2>Create new Booking</h2>
            <div className='row mt-5 justify-content-center'>
                <div className='col-12 mb-3'>
                    <span className='pe-3'>Select costing criteria </span>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="subscription1"
                            value="1"
                            checked={payload.isMonthly}
                            onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="subscription1">Per Month</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="subscription2"
                            value="2"
                            checked={payload.isDaily}
                            onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="subscription2">Per Day</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="subscription3"
                            value="3"
                            checked={payload.isHourly}
                            onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="subscription3">Per Hour</label>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='form-floating mb-3'>
                        <select
                            className='form-select'
                            id='locations'
                            required
                            value={payload.location}
                            onChange={(e) => {
                                setPayload({ ...payload, location: e.target.value });
                            }}>
                            <option></option>
                            {locationsData
                                .map((each) => <option key={each._id} value={each._id}>{each.title}</option>
                                )}
                        </select>
                        <label htmlFor='locations'>Location *</label>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='form-floating mb-3'>
                        <select
                            className='form-select'
                            id='inventory'
                            required
                            value={payload.inventory}
                            onChange={e => {
                                setPayload({ ...payload, inventory: e.target.value });
                            }}>
                            <option></option>
                            {inventoryData
                                .map((each) => <option key={each._id} value={each._id}>{each.title}</option>
                                )}
                        </select>
                        <label htmlFor='inventory'>Inventory *</label>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='form-floating mb-3'>
                        <input
                            type='date'
                            className='form-control'
                            id='startDate'
                            value={moment(payload.startDate).format("YYYY-MM-DD")}
                            onChange={e => {
                                setPayload({ ...payload, startDate: computeDate(e.target.value) });
                            }}
                            required
                        />
                        <label htmlFor='startDate'>Start Date *</label>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='form-floating mb-3'>
                        <input
                            type='date'
                            className='form-control'
                            id='endDate'
                            value={moment(payload.endDate).format("YYYY-MM-DD")}
                            onChange={e => {
                                setPayload({ ...payload, endDate: computeDate(e.target.value) });
                            }}
                            required
                        />
                        <label htmlFor='endDate'>End Date *</label>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='form-floating mb-3'>
                        <input
                            type='time'
                            className='form-control'
                            id='hoursFrom'
                            value={moment(payload.hoursFrom).format("HH:mm:ss")}
                            min={moment().format('HH:mm')}
                            onChange={e => {
                                setPayload({ ...payload, hoursFrom: computeTime(e.target.value) });
                            }}
                            required
                        />
                        <label htmlFor='hoursFrom'>Hours from</label>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='form-floating mb-3'>
                        <input
                            type='time'
                            className='form-control'
                            id='hoursTo'
                            value={moment(payload.hoursTo).format("HH:mm:ss")}
                            min={moment().format('HH:mm')}
                            onChange={e => {
                                setPayload({ ...payload, hoursTo: computeTime(e.target.value) });
                            }}
                            required
                        />
                        <label htmlFor='hoursTo'>Hours to</label>
                    </div>
                </div>
                {isAvailable ? 
                    payload.inventory.length ? <div className='mb-3 text-success'> Looks Good! </div> : 
                    null :
                    <div className='mb-3 text-danger'>Inventory is not available at the selected date and time.</div>
                }
                <div className='col-6'>
                    <div className='form-floating mb-3'>
                        <AsyncTypeahead
                            id='users'
                            className='form-control'
                            isLoading={isLoading}
                            filterBy={() => true}
                            minLength={3}
                            onSearch={handleUsers}
                            options={usersData}
                            onChange={e => setPayload({ ...payload, createdBy: e[0]._id })}
                            labelKey='email'
                            inputProps={{ className: 'revert-form-control' }}
                            renderMenuItemChildren={(option) => (
                                <>
                                    <img
                                        src={option.image ?? WHLogo}
                                        className='img-fluid'
                                        style={{
                                            height: '24px',
                                            marginRight: '10px',
                                            width: '24px',
                                        }}
                                        alt={option.firstName}
                                    />
                                    <span>{option.firstName} - {option.email}</span>
                                </>
                            )}
                        />
                        <label htmlFor='users'>Search User *</label>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='form-floating mb-3'>
                        <select
                            className='form-select'
                            id='paymentMode'
                            required
                            value={payload.paymentMode}
                            onChange={e => setPayload({ ...payload, paymentMode: e.target.value })}>
                            <option></option>
                            {paymentModes
                                .map((each, idx) => <option key={idx} value={each}>{each}</option>
                                )}
                        </select>
                        <label htmlFor='paymentMode'>Payment Mode *</label>
                    </div>
                </div>
                <div className='col-9'>
                    <div className='form-floating mb-3'>
                        <textarea
                            className='form-control'
                            id='notes'
                            value={payload.notes}
                            onChange={e => setPayload({ ...payload, notes: e.target.value })}
                            placeholder='Add details for the payment'
                        ></textarea>
                        <label htmlFor='notes'>Notes</label>
                    </div>
                </div>
                <div className='col-3'>
                    <div className="form-check pt-3">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={payload.isPaid}
                            id="isPaid"
                            onChange={e => setPayload({ ...payload, isPaid: e.target.checked })}
                        />
                        <label className="form-check-label" htmlFor="isPaid">
                            Mark as paid
                        </label>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='form-floating mb-3'>
                        <input
                            type='number'
                            className='form-control'
                            id='amount'
                            value={payload.amount}
                            onChange={e => setPayload({ ...payload, amount: e.target.value })}
                        />
                        <label htmlFor='amount'>Amount</label>
                    </div>
                </div>
                <div className='col-3 m-auto ms-0 mt-2'>
                    <button
                        className='btn btn-primary btn-wh pt-2 pb-2 text-nowrap'
                        onClick={handleAmount}>
                        Get Amount
                    </button>
                </div>
                <div className='col-7' />
                <div className='col-3'>
                    <button
                        className='btn btn-primary btn-wh px-5'
                        onClick={handleSubmit}>Submit</button>
                </div>
            </div>
        </div>
    );
}

export default CreateBooking;