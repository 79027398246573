const host = process.env.NODE_ENV === "production" 
    ? "https://api.workhall.co/v1" : "http://localhost:3001/v1";

// const host = "https://api.workhall.co/v1";

export const login = `${host}/auth/login`;
export const register = `${host}/auth/register`;
export const users = `${host}/users`;
export const locations = `${host}/locations`;
export const inventory = `${host}/inventory`;
export const bookings = `${host}/bookings`;