import { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { FiMapPin, FiMoreVertical, FiPlus, FiSearch } from 'react-icons/fi'
import swal from 'sweetalert';
import { locations } from '../../config/api-routes';
import Loader from '../common/Loader';
import { BsPinMapFill } from 'react-icons/bs';

const header = ['Title', 'Address', 'Phone', 'Manager','Map'];

function LocationList() {
    const [data, setData] = useState([]);
    const [title, setTitle] = useState('');
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    const fetchLocations = useCallback(async () => {
        try {
            setLoading(true);
            const { data } = await axios.get(`${locations}?title=${title}`);
            setData(data);
            setLoading(false);
        } catch (error) {
            swal('Failed', error.response.data.message, 'error').then(() => history.push('/locations/new'));
        }
    }, [history, title]);

    useEffect(() => {
        fetchLocations();
    }, [fetchLocations]);

    return (
        <div>
            <div className='users container m-4 pe-5 mt-4'>
                <div className='col-4'>
                    <h2 className='fw-bold'>Locations</h2>                 
                    <p className='text-secondary'>Explore, Expand, and Thrive. <BsPinMapFill className='text-danger'/></p>
                </div>
                <div className='row mx-4 mb-3 pt-4 '>                
                    
                    <div className='col-9'>
                        <div className=' input-group '>
                            <span className='input-group-text'><FiSearch/></span>
                            <input 
                                type='text' 
                                className='form-control search' 
                                placeholder='Search by title' 
                                onChange={e => { setTitle(e.target.value); fetchLocations(); }}
                            />
                        </div>                    
                    </div>
                    <div className='col-2 offset-1 d-flex justify-content-end'>
                        <Link to='/locations/new' className='btn btn-primary btn-wh w-50'><FiPlus size={20}/></Link>
                    </div>
                </div>
                {loading ? <Loader/>: ( 
                <div className='bg-white rounded m-4'>
                    {data.length ? (
                        <div className='m-4 py-4'>
                            <div className='table-responsive m-2'>
                                <table className='table shadow'>
                                    <thead className='table-primary-head'>
                                        <tr>
                                            <td>#</td>
                                            {header.map((each, idx) => (
                                                <td key={idx}>{each}</td>
                                            ))}
                                            <td></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((each = {}, idx) => (
                                            <tr key={idx}>
                                                <th scope='row'>{idx + 1}</th>
                                                <td>{each.title}</td>
                                                <td>{each.address.slice(0, 20)}...</td>                                            
                                                <td>{each.phone}</td>
                                                <td>{each.manager.firstName} {each.manager.lastName}</td>
                                                <td><a href={each.mapLocation} target='_blank' rel="noreferrer"><FiMapPin /></a></td>
                                                <td className='text-end'>
                                                    <div className="dropdown">
                                                        <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <FiMoreVertical />
                                                        </span>
                                                        <ul className="dropdown-menu">
                                                            <li><Link to={`/locations/${each._id}`} className="dropdown-item">Manage</Link></li>
                                                            <li><Link to={`/locations/images/${each._id}`} className="dropdown-item">Upload Images</Link></li>
                                                            {/* <li><button className="dropdown-item" type="button">Upload Videos</button></li> */}
                                                            <li><Link to={`/bookings/location/${each._id}`} className="dropdown-item" type="button">Show Bookings</Link></li>
                                                        </ul>
                                                    </div>
                                                </td>                                            
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>                        
                        </div>
                    ) : <p>No Locations found</p>}
                </div>)}
            </div>
        </div>
    );
}

export default LocationList;