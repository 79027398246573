import axios from 'axios';
import { Link } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';
import swal from 'sweetalert';
import moment from 'moment';
import classNames from 'classnames';
import { FiMoreVertical, FiPlus } from 'react-icons/fi';
import { HiCheck } from 'react-icons/hi';
import { GiBackwardTime } from 'react-icons/gi'
import { bookings } from '../../config/api-routes';
import Pagination from '../Pagination';
import Invoice from '../Invoice/invoice';
import Loader from '../common/Loader';


const header = ['Customer', 'Inventory', 'Start Date', 'End Date', 'Note', 'Amount (PKR)', 'Payment Mode', 'Paid', 'Created At'];

function BookingList() {
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        total: 0,
        page: 1,
    });
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [hoursFrom, setHoursFrom] = useState('');
    const [hoursTo, setHourTo] = useState('');
    const [isPaid, setIsPaid] = useState('0');
    const [paymentMode, setPaymentMode] = useState('');
    const [isArchived, setIsArchived] = useState(false);
    const [loading, setLoading] = useState(true);

    const fetchBookings = useCallback(async () => {
        try {
            setLoading(true);
            let url = `${bookings}?page=${pagination.page}&isPaid=${isPaid}&paymentMode=${paymentMode}`;
            if (startDate) {
                url += `&startDate=${startDate}`;
            }
            if (endDate) {
                url += `&endDate=${endDate}`;
            }
            if (hoursFrom) {
                url += `&hoursFrom=${hoursFrom}`;
            }
            if (hoursTo) {
                url += `&hoursTo=${hoursTo}`;
            }
            if (isArchived) {
                url += `&showArchive=1`;
            }
            const { data } = await axios.get(url);
            setPagination({
                total: data.total,
                page: data.page,
            });
            
            const sortedData = data.bookings
                .filter(each => (
                    each.inventory !== null
                    || each.location !== null
                    || each.createdBy !== null)
                )
                .sort((a, b) => moment(b.endDate).diff(a.endDate));
            setData(sortedData);
            setLoading(false);
        } catch (error) {
            swal('Failed', error.response.data.message, 'error');
        }
    }, [pagination.page, startDate, endDate, hoursFrom, hoursTo, isPaid, paymentMode, isArchived]);

    useEffect(() => {
        fetchBookings();
    }, [fetchBookings]);

    const handleArchive = id => () => {
        swal({
            title: "Are you sure?",
            text: "Once archive, you will be able to recover this record!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async willArchive => {
            if (willArchive) {
                try {
                    const response = await axios.put(`${bookings}/archive/${id}`)
                    if (response) {
                        swal('Good', 'Booking Archived', 'success').then(fetchBookings());
                    }
                } catch (error) {
                    swal('Failed', error.response.data.message, 'error')
                }
            } else {
                swal("This record not Archived!");
            }
        });
    }

    const handleCancel = id => () => {
        swal({
            title: "Are you sure?",
            text: "Booking Cancel",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async willCancel => {
            if (willCancel) {
                try {
                    const response = await axios.get(`${bookings}/cancel/${id}`)
                    if (response) {
                        swal('Good', 'Booking Cancelled', 'success').then(fetchBookings());
                    }
                } catch (error) {
                    swal('Failed', error.response.data.message, 'error')
                }
            } else {
                swal("This record not cancelled!");
            }
        });
    }

    return (
        <div>
            <div className='users container m-4 pe-5 mt-4'>
                <div className='col-4'>
                    <h2 className='fw-bold'>Bookings</h2>
                    <p className='text-secondary'>Streamline your work hall reservations.</p>
                </div>
                <div className='row mb-3'>
                    <div className=' mx-5'>
                        <div className='row d-flex justify-content-center'>
                            <div className='col-md-2 '>
                                <div className="form-check pt-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="arcived"
                                        checked={isArchived}
                                        onChange={e => setIsArchived(e.target.checked)}
                                    />
                                    <label className="form-check-label" htmlFor="isPaid">
                                        Show Archived
                                    </label>
                                </div>
                            </div>
                            <div className='col-md-2 '>
                                <span className='px-1 '>Date from</span>
                                <div className='d-flex'>
                                    <input
                                        type='date'
                                        className='form-control d-inline text-secondary'
                                        onBlur={e => setStartDate(new Date(e.target.value).valueOf())}
                                    />
                                </div>
                            </div>
                            <div className='col-md-2 '>
                                <span className='px-1'>Date to</span>
                                <div className='d-flex'>
                                    <input
                                        type='date'
                                        className='form-control d-inline text-secondary'
                                        onBlur={e => setEndDate(new Date(e.target.value).valueOf())}
                                    />
                                </div>
                            </div>
                            <div className='col-md-2'>
                                Payment Mode
                                <select className='form-select' onChange={e => setPaymentMode(e.target.value)}>
                                    <option value=''>All</option>
                                    <option value='cash'>Cash</option>
                                    <option value='cheque'>Cheque</option>
                                    <option value='online'>Online</option>
                                </select>
                            </div>
                            <div className='col-md-2'>
                                Paid
                                <select className='form-select' onChange={e => setIsPaid(e.target.value)}>
                                    <option value='0'>All</option>
                                    <option value='1'>Paid</option>
                                </select>
                            </div>
                            <div className='col-md-2'>
                                <Link to='/bookings/new' className='btn btn-primary btn-wh mt-4 w-50'><FiPlus size={20} /></Link>
                            </div>
                        </div>
                    </div>
                </div>
                {loading ? <Loader/> : (
                <div className='bg-white rounded'>
                    {data.length ? (
                        <div className='m-4 py-4'>
                            <div className='table-responsive'>
                                <table className='table shadow'>
                                    <thead className='table-primary-head'>
                                        <tr>
                                            <td>#</td>
                                            {header.map((each, idx) => (
                                                <td className={each === 'Start Date' || each === 'End Date' || each === 'Created At' ? "w-110" : '' } key={idx}>{each}</td>
                                            ))}
                                            <td />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((each, idx) => (
                                            <tr key={idx} className={each.isCancelled ? "text-decoration-line-through" : null}>
                                                <th scope='row'>{`${((pagination.page-1)*10)+idx+1}`}</th>
                                                <td>{`${each.createdBy.firstName} ${each.createdBy.lastName}`}</td>
                                                <td><b>{each.inventory.title}</b> - {each.location.title}</td>
                                                <td>{moment(each.startDate + each.hoursFrom + 18000000).format('DD-MMM-YYYY hh:mm a')}</td>
                                                <td>{moment(each.endDate + each.hoursTo + 18000000).format('DD-MMM-YYYY hh:mm a')}</td>
                                                <td>{each.notes}</td>
                                                <td className='text-center'>{each.amount.toLocaleString()}</td>
                                                <td>{each.paymentMode}</td>
                                                <td className={classNames(each.isPaid ? 'text-success' : 'text-secondary')}>{each.isPaid ? <div><HiCheck />Paid</div> : <div><GiBackwardTime />Pending</div>} </td>
                                                <td>{moment(each.createdAt).format('DD-MMM-YYYY, h:mm A')}</td>
                                                <td className='text-end'>
                                                    <div className="dropdown">
                                                        <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <FiMoreVertical />
                                                        </span>
                                                        <ul className="dropdown-menu">
                                                            <li><Link to={`/bookings/${each._id}`} className="dropdown-item">Manage</Link></li>
                                                            <li><button className="dropdown-item" onClick={handleArchive(each._id)}>Archive</button></li>
                                                            <li><button className="dropdown-item" onClick={handleCancel(each._id)}>Cancel</button></li>
                                                            <li><button className="dropdown-item" type="button">Mark as paid</button></li>
                                                            <li>
                                                                <BlobProvider
                                                                    document={<Invoice invoice={each} />}    // invoice data is a hardcoded const declared above which is to be replaced with real Bookings data 
                                                                    className="dropdown-item">
                                                                    {({ loading, url }) => (
                                                                        <a
                                                                            href={url}
                                                                            target="_blank"
                                                                            type="button"
                                                                            className="dropdown-item"
                                                                            rel="noreferrer">
                                                                            {loading ? 'Loading invoice ...' : 'Open invoice in new tab'}
                                                                        </a>
                                                                    )
                                                                    }
                                                                </BlobProvider>
                                                            </li>
                                                            <li>
                                                                <PDFDownloadLink
                                                                    document={<Invoice invoice={each} />}    // invoice data is a hardcoded const declared above which is to be replaced with real Bookings data 
                                                                    className="dropdown-item"
                                                                    type="button" >
                                                                    {({ loading }) => loading ? "Loading document..." : "Download invoice PDF"}
                                                                </PDFDownloadLink>
                                                            </li>
                                                        </ul>
                                                    </div></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <Pagination
                                currentPage={pagination.page}
                                setCurrentPage={(pageRec) => setPagination({ ...pagination, page: pageRec })}
                                totalRecords={pagination.total}
                            />
                        </div>
                    ) : <p>No bookings found</p>}
                </div>)}
            </div>
        </div>
    );
}


export default BookingList;