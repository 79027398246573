import { useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Link, useHistory } from 'react-router-dom';
import LogoImage from '../../assets/logo.png';
import { register } from '../../config/api-routes';
import ReactInputMask from 'react-input-mask';

export default function RegisterPage() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [city, setCity] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const history = useHistory();
    const [validationError, setError] =useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        city: '',
        password: '',        
    })

    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            firstName,
            lastName,
            phone:(phone.includes('+') ? '' : '+') + phone.replace(/[- )(]/g, ''),
            email,
            city,
            password,
            confirmPassword,
        };

        try {
            const { data } = await axios.post(register, payload);
            if (data) {
                swal('Good Job!', 'Account created successfully', 'success')
                    .then(() => history.push('/'));
            }
        } catch (error) {
            handleError(error.response.data.message);         
        }
    };

    const handleError = (error) => {
        const updateError = {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            city: '',
            password: '',   
        }
        if(!firstName) {            
            updateError.firstName= 'First name is required'
        } 
        if(!lastName) {
            updateError.lastName='Last name is required'
        }  
        if(!city) {
            updateError.city='City is required'
        }    
        if(Array.isArray(error)) {
            error.forEach((each) => {
                switch(each) {
                    case 'email must be an email' : updateError.email = 'Enter a valid email';
                    break;
                    case 'phone must be a phone number' : updateError.phone = 'Enter a valid phone number';
                    break;
                    case 'password must be longer than or equal to 8 characters' : updateError.password = 'Password must be longer than or equal to 8 characters';
                    break;
                    case 'confirmPassword must be longer than or equal to 8 characters' : {
                        if(!updateError.password){
                            updateError.password = 'Passwords do not match'
                        }
                    }
                }
            })

        } else {
            switch(error){
                case 'Passwords do not match': updateError.password=error;
                break;
                case 'User with this email already exists': updateError.email=error;
                break;
            }
        }
        setError(updateError);        
    }
    return (
        <div className='container-fluid'>
            <form className='auth-form'>
                <div className='text-center'>
                    <img src={LogoImage} className='img-fluid' width={50} height={50} alt='Workhall Logo' />
                </div>
                <h2 className='text-center mb-3'>Create an account</h2>
                <div className='form-floating mb-3'>
                    <input
                        type='text'
                        className={`form-control ${validationError.firstName ? 'is-invalid' : ''}`}
                        id='firstName'
                        value={firstName}
                        onChange={e => setFirstName(e.target.value)}
                        required
                    />
                    <label htmlFor='firstName' className='form-label'>First Name *</label>
                    <div id="firstNameFeedback" className="invalid-feedback">
                        {validationError.firstName}
                    </div>
                </div>
                <div className='form-floating mb-3'>
                    <input
                        type='text'
                        className={`form-control ${validationError.lastName ? 'is-invalid' : ''}`}
                        id='lastName'
                        value={lastName}
                        onChange={e => setLastName(e.target.value)}
                        required
                    />
                    <label htmlFor='lastName' className='form-label'>Last Name *</label>
                    <div id="lastNameFeedback" className="invalid-feedback">
                        {validationError.lastName}
                    </div>
                </div>
                <div className='form-floating mb-3'>
                    <input
                        type='email'
                        className={`form-control ${validationError.email ? 'is-invalid' : ''}`}
                        id='email'
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                    />
                    <label htmlFor='email' className='form-label'>Email address *</label>
                    <div id="emailFeedback" className="invalid-feedback">
                        {validationError.email}
                    </div>
                </div>
                <div className='form-floating mb-3'>
                    <ReactInputMask
                        type='text'
                        className={`form-control ${validationError.phone ? 'is-invalid' : ''}`}
                        id='phone'
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                        mask={"+(99) 999 9999999"}
                    />
                    <label htmlFor='phone' className='form-label'>Phone Number</label>
                    <div id="phoneFeedback" className="invalid-feedback">
                        {validationError.phone}
                    </div>
                </div>
                <div className='form-floating mb-3'>
                    <input
                        type='text'
                        className={`form-control ${validationError.city ? 'is-invalid' : ''}`}
                        id='city'
                        value={city}
                        onChange={e => setCity(e.target.value)}
                    />
                    <label htmlFor='city' className='form-label'>City</label>
                    <div id="cityFeedback" className="invalid-feedback">
                        {validationError.city}
                    </div>
                </div>
                <div className='form-floating mb-3'>
                    <input
                        type='password'
                        className={`form-control ${validationError.password ? 'is-invalid' : ''}`}
                        id='password'
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        required
                    />
                    <label htmlFor='password' className='form-label'>Password *</label>
                    <div id="passwordFeedback" className="invalid-feedback">
                        {validationError.password}
                    </div>
                </div>
                <div className='form-floating mb-3'>
                    <input
                        type='password'
                        className={`form-control ${validationError.password ? 'is-invalid' : ''}`}
                        id='cpassword'
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                        required
                    />
                    <label htmlFor='cpassword' className='form-label'>Confirm Password *</label>
                </div>
                <div className='form-check'>
                    <input className='form-check-input' type='checkbox' value='' id='remeberme' />
                    <label className='form-check-label' htmlFor='remeberme'>
                        I agree to the terms and conditions
                    </label>
                </div>
                <div className='text-center mt-3 mb-3'>
                    <button className='btn btn-primary btn-wh w-100' onClick={handleSubmit}>Sign Up</button>

                    <p className='pt-3'>Already have an account? <Link to='/'> Login here</Link></p>
                </div>
            </form>
        </div>
    );
}