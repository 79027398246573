import axios from 'axios';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import swal from 'sweetalert';
import moment from 'moment';
import { FiMoreVertical } from 'react-icons/fi'
import { bookings } from '../../config/api-routes';

const header = ['Customer', 'Start Date', 'End Date', 'Amount', 'Payment Mode', 'Paid', 'Created At'];

function InventoryBookingList() {
    const [data, setData] = useState([]);
    const [inventory, setInventory] = useState({});
    const [pagination, setPagination] = useState({
        total: 0,
        page: 1,
    });
    const params = useParams();
    const history = useHistory();

    const fetchBookings = useCallback(async () => {
        try {
            const { data } = await axios.get(`${bookings}/inventory/${params.id}?page=${pagination.page}`);
            setData(data.bookings);
            setInventory(data.inventory);
            setPagination({
                total: data.total,
                page: data.page,
            });
        } catch (error) {
            swal('Failed', error.response.data.message, 'error').then(() => history.push('/inventory'));
        }
    }, [pagination.page]);

    useEffect(() => {
        fetchBookings();
    }, [fetchBookings]);

    return (
        <div className='users container m-4 pe-5 mt-4'>            
                <div className='col-3'>
                    <h2 className='fw-bold'>Bookings for {inventory.title}</h2>
                </div>                
            
            {data.length ? (
                <div className='bg-white rounded mx-3'>
                    <div className='m-4 py-4'>
                        <div className='table-responsive'>
                            <table className='table shadow'>
                                <thead className='table-primary-head'>
                                    <tr>
                                        <td>#</td>
                                        {header.map((each, idx) => (
                                            <td key={idx}>{each}</td>
                                        ))}
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((each = {}, idx) => (
                                        <tr key={idx}>
                                            <th scope='row'>{idx + 1}</th>
                                            <td>{each.createdBy.firstName + ' ' + each.createdBy.lastName }</td>
                                            <td>{moment(each.startDate).format('DD-MMM-YYYY')}</td>
                                            <td>{moment(each.endDate).format('DD-MMM-YYYY')}</td>
                                            <td>{each.amount}</td>
                                            <td>{each.paymentMode}</td>
                                            <td>{each.isPaid ? 'Yes': 'No'}</td>
                                            <td>{moment(each.createdAt).format('DD-MMM-YYYY')}</td>
                                            <td className='text-end'>
                                                <div className="dropdown">
                                                    <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <FiMoreVertical />
                                                    </span>
                                                    <ul className="dropdown-menu">
                                                        <li><Link to={'/bookings/' + each._id} className="dropdown-item">Manage</Link></li>
                                                        <li><button className="dropdown-item" type="button">Mark as paid</button></li>
                                                    </ul>
                                                </div></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>  
                    </div>                                      
                </div>
            ) : <p>No bookings found</p>}
            <nav aria-label='Table Pagination'>
                <ul className='pagination justify-content-end'>
                    <li className='page-item disabled'>
                        <a className='page-link'>Previous</a>
                    </li>
                    <li className='page-item'><a className='page-link' href='#'>1</a></li>
                    <li className='page-item'><a className='page-link' href='#'>2</a></li>
                    <li className='page-item'><a className='page-link' href='#'>3</a></li>
                    <li className='page-item'>
                        <a className='page-link' href='#'>Next</a>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default InventoryBookingList;