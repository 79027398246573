import { useSelector } from "react-redux";

export default function DashboardPage() {
    const auth = useSelector(state => state.auth);
    return (
        <div className="container">
            <h3 className="fw-bold">Hello {auth.user.name.split(" ")[0]}! 👋</h3>
            <p className="text-secondary">Let's check your stats today!</p>
        </div>
    );
}