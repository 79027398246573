import { useCallback, useEffect, useState } from "react"
import axios from 'axios';
import swal from 'sweetalert';
import { useParams } from 'react-router-dom';
import { inventory } from '../../config/api-routes';

function UploadInventoryImages() {
    const [images, setImages] = useState([]);
    const [uploaded, setUploaded] = useState([]);
    const params = useParams();

    const handleChange = (e) => {
        const files = e.target.files;
        setImages(files);
    };

    const getImages = useCallback(async () => {
        try {
            const { data } = await axios.get(`${inventory}/${params.id}`);
            setUploaded(data.images);
        } catch (error) {
            swal('Failed', error.response.data.message, 'error');
        }
    }, [params.id]);

    useEffect(() => {
        getImages();
    }, [getImages]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!images) {
            swal('Failed', 'No images to upload', 'error');
            return;
        }

        const formData = new FormData();
        for (let i = 0; i < images.length; i++) {
            formData.append('files', images[i]);
        }

        try {
            const { data } = await axios.put(`${inventory}/images/${params.id}`, formData);
            if (data) {
                swal('Good Job!', 'Image(s) uploaded', 'success');
            }
        } catch (error) {
            swal('Failed', error.response.data.message, 'error');
        }
    }

    return (
        <div className="container w-50 bg-white p-3 rounded m-auto mt-5">
            <h2>Upload Images</h2>
            <div className='row my-5 justify-content-center'>
                <div className='col-10'>
                    <div className='form-floating mb-3'>
                        <input 
                            type='file' 
                            className='form-control' 
                            id='title' 
                            onChange={handleChange}
                            required
                            multiple
                        />
                        <label htmlFor='title'>Select images to upload</label>
                    </div>
                </div>
                <div className="col-4">
                    <button className='btn btn-primary btn-wh w-100' onClick={handleSubmit}>Submit</button>
                </div>

                {uploaded ? (
                    <div className="col-12">
                        <div className="mt-5">
                            {uploaded.map((each, idx) => (
                                <img 
                                    src={each} 
                                    className="img-fluid rounded shadow-sm pe-3" 
                                    width={200} 
                                    key={idx} 
                                    alt={idx + params.id} 
                                />
                            ))}
                        </div>
                    </div>
                ) : <div className="col-12 text-center mt-5 text-danger">No images found, kindly upload images</div>}
            </div>
        </div>
    );
}

export default UploadInventoryImages;