import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RiEyeLine, RiEyeCloseLine } from "react-icons/ri";
import { loginUser } from '../../store/actions/auth';
import LogoImage from '../../assets/logo.png';

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const auth = useSelector(state => state.auth);
    const errors = useSelector(state => state.errors);    
    const history = useHistory();
    const dispatch = useDispatch();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [validationError, setError] = useState({
        username : '',
        password : ''
    })
    
    useEffect(() => {
        if (auth.isAuthenticated) {
            history.push('/dashboard');
        }
    }, [auth.isAuthenticated, history]);

    useEffect(() => {
        handleError();
    },[errors])

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const payload = {
            email,
            password,
        };
        
        dispatch(loginUser(payload));
    }

    const handleError = () => {
        if (errors.message) {
            if(Array.isArray(errors.message)){
                const updateError = {
                    username: '',
                    password: ''
                }
                errors.message.forEach((errorMessage) => {
                    switch(errorMessage) {
                        case 'email must be an email': updateError.username = errorMessage;
                        break;
                        case 'password should not be empty': updateError.password = errorMessage;
                        break;
                    }                    
                  });
                setError(updateError)
            }
            else {
                setError({                    
                    password: errors.message 
                })
            }
        }

    }
    return (
        <div className='container-fluid'>
            <form className='auth-form auth-login needs-validation' noValidate>
                <div className='text-center'>
                    <img src={LogoImage} className='img-fluid' width={50} height={50} alt='Workhall Logo' />
                </div>
                <h2 className='text-center mb-3'>Sign in to the Work Hall Community</h2>
                <div className='form-floating mb-4'>
                    <input 
                        type='email' 
                        className={`form-control ${validationError.username ? 'is-invalid' : ''}`}
                        id='email' 
                        placeholder='name@example.com' 
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                    />
                    <label htmlFor='email' className='form-label'>Email address</label>
                    <div id="emailFeedback" className="invalid-feedback">
                        Enter a valid email.
                    </div>
                </div>                
                <div className='form-floating mb-4 position-relative'>                   
                    <input 
                        type={passwordVisible ? "text" : "password"}
                        className={`form-control ${validationError.password ? 'is-invalid' : ''}`}
                        id='password' 
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        required
                    />
                    <div className={`position-absolute end-0 top-50 translate-middle-y me-2 ${validationError.password ? 'password-icon-container' : ''}`}>
                        {passwordVisible ? <RiEyeCloseLine onClick={togglePasswordVisibility} size={20} className=''/> : <RiEyeLine onClick={togglePasswordVisibility} size={20} className=''/>}
                    </div>                    
                    <label htmlFor='password' className='form-label'>Password</label>   
                    <div id="passwordFeedback" className="invalid-feedback">
                        Incorrect credentials.
                    </div>                                     
                </div>
                <div className='row'>
                    <div className='col-sm-12 col-md-6'>
                        <div className='form-check'>
                            <input className='form-check-input' type='checkbox' value='' id='remeberme' />
                            <label className='form-check-label' htmlFor='remeberme'>
                                Remember me
                            </label>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6 text-md-end'>
                        <span className='lostpassword'>Lost password?</span>
                    </div>
                </div>
                <div className='text-center mt-3 mb-3'>
                    <button className='btn btn-primary btn-wh w-100' onClick={handleSubmit}>Sign In</button>
                    
                    <p className='pt-3'>Not registered? <Link to='/register'>Create account</Link></p>
                </div>
                
            </form>
        </div>
    );
}

export default LoginPage;
